import Application from "../Components/App/Application";
import { EditApplication } from "../Components/App/EditApplication";
import { CampaignMaster } from "../Components/Campaign Master/CampaignMaster";
import { ChannelMaster } from "../Components/Channel Master/ChannelMaster";
import ClientSubscription from "../Components/Client Subscription/ClientSubscription";
import Client from "../Components/Client/Client";
import CreateClient from "../Components/Client/CreateClient";
import EditClient from "../Components/Client/EditClient";
import MastersMenu from "../Components/Master Menu/MasterMenu";
import AdjustmentReason from "../Components/Master/Adjustment Reason/AdjustmentReason";
import Breakcode from "../Components/Master/Breakcode/Breakcode";
import CompanyType from "../Components/Master/CompanyType/CompanyType";
import DocumentType from "../Components/Master/DocumentType/DocumentType";
import GSTSlab from "../Components/Master/GSTSlab/GSTSlab";
import GSTTreatment from "../Components/Master/GSTTreatment/GSTTreatment";
import HSNMaster from "../Components/Master/HSN Master/HSNMaster";
import IndustryType from "../Components/Master/IndustryType/IndustryType";
import InspectionType from "../Components/Master/InspectionType/InspectionType";
import OrganisationType from "../Components/Master/OrganisationType/OrganisationType";
import ProductType from "../Components/Master/Product Type/ProductType";
import StatusMaster from "../Components/Master/Status/StatusMaster";
import StoreType from "../Components/Master/StoreType/StoreType";
import TaskCategory from "../Components/Master/Task Category/TaskCategory";
import VendorType from "../Components/Master/VendorType/VendorType";
import Module from "../Components/Module/Module";
import { Movement } from "../Components/Movement Master/Movement";
import CreateRole from "../Components/Role/CreateRole";
import Roles from "../Components/Role/Roles";
import UpdateRole from "../Components/Role/UpdateRole";
import { ScenarioMapping } from "../Components/ScenarioMapping/ScenarioMapping";
import { AddScreen } from "../Components/Screen/AddScreen";
import Screen from "../Components/Screen/Screen";
import { UpdateScreen } from "../Components/Screen/UpdateScreen";
import Subcription from "../Components/Subcriptions/Subcription";
import { Transaction } from "../Components/Transaction Master/Transaction";
import User from "../Components/User/User";

// console.log("url",`${process.env.PUBLIC_URL}/application`)
const routes = [
    { path: `${process.env.PUBLIC_URL}/`,  name: "application", element:Application },
    { path: `${process.env.PUBLIC_URL}/application`,  name: "application", element:Application },
    { path: `${process.env.PUBLIC_URL}/edit-application`,  name: "application", element: EditApplication},
    { path: `${process.env.PUBLIC_URL}/masterMenu`,  name: "masterMenu", element: MastersMenu },

    { path: `${process.env.PUBLIC_URL}/module`,  name: "module", element:Module },
    { path: `${process.env.PUBLIC_URL}/client`,  name: "client", element:Client },
    { path: `${process.env.PUBLIC_URL}/client/create-client`,  name: "client", element:CreateClient },
    { path: `${process.env.PUBLIC_URL}/update-client`,  name: "client", element:EditClient },
    { path: `${process.env.PUBLIC_URL}/screen`,  name: "screen", element:Screen },
    { path: `${process.env.PUBLIC_URL}/add-screen`,  name: "screen", element:AddScreen },
    { path: `${process.env.PUBLIC_URL}screen/update-screen`,  name: "screen", element:UpdateScreen },
    { path: `${process.env.PUBLIC_URL}/user`,  name: "user", element:User },
    { path: `${process.env.PUBLIC_URL}/subcription`,  name: "Subcription", element:Subcription },
    { path: `${process.env.PUBLIC_URL}/role`,  name: "Role", element:Roles },
    { path: `${process.env.PUBLIC_URL}/create-role`,  name: "Create Role", element:CreateRole },
    { path: `${process.env.PUBLIC_URL}/update-role`,  name: "Update Role", element:UpdateRole },
    { path: `${process.env.PUBLIC_URL}/client-subscription`,  name: "Client Subscription", element:ClientSubscription },
    { path: `${process.env.PUBLIC_URL}/channel-master`,  name: "Channel Master", element:ChannelMaster },
    { path: `${process.env.PUBLIC_URL}/campaign-master`,  name: "Channel Master", element:CampaignMaster },
    { path: `${process.env.PUBLIC_URL}/transaction`,  name: "Channel Master", element:Transaction },
    { path: `${process.env.PUBLIC_URL}/movement`,  name: "Channel Master", element:Movement },
    { path: `${process.env.PUBLIC_URL}/gstSlab`,  name: "GST Slab",  element: GSTSlab  },
    { path: `${process.env.PUBLIC_URL}/companyType`, name: "Company Type",  element: CompanyType },
    { path: `${process.env.PUBLIC_URL}/document_type`, name: "Document Type",  element: DocumentType },
    { path: `${process.env.PUBLIC_URL}/gstTreatment`, name: "GST Treatment",  element: GSTTreatment },
    { path: `${process.env.PUBLIC_URL}/orgnisationType`, name: "Organisation Type",  element: OrganisationType},

    { path: `${process.env.PUBLIC_URL}/storeType`,  name: "Store Type",  element: StoreType },
    { path: `${process.env.PUBLIC_URL}/statusMaster`, name: "Status Master",  element: StatusMaster},
    { path: `${process.env.PUBLIC_URL}/task-category`, name: "Task Category",  element: TaskCategory},
    { path: `${process.env.PUBLIC_URL}/scenario-mapping`, name: "Scenario Mapping",  element: ScenarioMapping},
    { path: `${process.env.PUBLIC_URL}/inspectionType`, name: "Inspection Type",  element: InspectionType},
    { path: `${process.env.PUBLIC_URL}/hsnmaster`, name: "HSN Master",  element: HSNMaster},
    { path: `${process.env.PUBLIC_URL}/adjustment-reason`, name: "Adjustment Reason",  element: AdjustmentReason},
    { path: `${process.env.PUBLIC_URL}/product-type`, name: "Product Type",  element: ProductType},
    { path: `${process.env.PUBLIC_URL}/vendor-type`, name: "Vendor Type",  element: VendorType},
    { path: `${process.env.PUBLIC_URL}/industry-type`, name: "Industry Type",  element: IndustryType},
    { path: `${process.env.PUBLIC_URL}/break-code`, name: "Break Code",  element: Breakcode},
];

export default routes;