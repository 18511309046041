import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";


const NavbarComp = () => {
  let navigate = useNavigate();
  let url = useLocation();
 
  function logoutuser() {
    localStorage.clear();
    navigate("/login");
 
  }

  function AdminOptions() {
    return (
      <Nav className="me-auto" id="main_link_nav">

          <>


           <Nav.Link onClick={() => navigate('/application')}
              active={url.pathname == "/application"}>Application
            </Nav.Link>
           <Nav.Link onClick={() => navigate('/masterMenu')}
              active={url.pathname == "/masterMenu"}>Master
            </Nav.Link>

            <Nav.Link onClick={() => navigate('/screen')}
              active={url.pathname == "/screen"}>Screen
            </Nav.Link>

            <Nav.Link onClick={() => navigate('/module')}
              active={url.pathname == "/module"}>Module
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/role')}
              active={url.pathname == "/role"}>Role
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/client')}
              active={url.pathname == "/client"}>Client
            </Nav.Link>
            
            <Nav.Link onClick={() => navigate('/user')}
              active={url.pathname == "/user"}>Users
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/subcription')}
              active={url.pathname == "/subcription"}>Subscription
            </Nav.Link>
            
            <Nav.Link onClick={() => navigate('/client-subscription')}
              active={url.pathname == "/client-subscription"}>Client Subscription
            </Nav.Link>
           
          <NavDropdown title="Campaign" id="nav-dropdown">
            
              <NavDropdown.Item onClick={() => navigate('/channel-master')}>Channel Master</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/campaign-master')}>Campaign Master</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Inventory" id="nav-dropdown">
            
              <NavDropdown.Item onClick={() => navigate('/transaction')}>Transaction</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/movement')}>Movement</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/scenario-mapping')}>Scenario Mapping</NavDropdown.Item>
            </NavDropdown>
           
          </>

        

      </Nav>
    )
  }







  return (
    <Navbar expand="lg" id="navbar" >

      <Container fluid style={{ padding: '0px 22px' }}>

        <Navbar.Toggle aria-controls="navbar-light-example" />

        <AdminOptions />
        {/* <Nav.Link onClick={() => navigate('/application')}
              active={url.pathname == "/application"}>Home
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/module')}
              active={url.pathname == "/module"}>Module
            </Nav.Link> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="navbar-light-example">

          <Nav className="ms-auto">
            <NavDropdown
              id="custom-dropdown"
              title={
                <span style={{ color: "black" }}>
                  Welcome Admin{" "}
                  <FontAwesomeIcon
                    style={{ color: "#8d8d8d" }}
                    icon={faGear}
                    size="lg"
                  />
                </span>
              }
              menuVariant="light"
              align={"start"}
            >
              <NavDropdown.Item onClick={() => logoutuser()}>
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  size="sm"
                  className="me-2"
                />
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavbarComp;
